import { useState, useEffect, useRef } from 'react';
import MyLogo from './../../Assets/Layer_40_copy.svg';
import './Navbar.css';
import Menu from './../../Assets/Mobile/Menu.png';
import X from './../../Assets/Mobile/x.png'

export default function Navbar() {
    const [activeDropdown, setActiveDropdown] = useState(null); // null означает, что ни один дропдаун не открыт
    const [isDropdownVisible, setDropdownVisibility] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("Uzb");
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownVisibility(!isDropdownVisible);
    };

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        setDropdownVisibility(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisibility(false);
        }
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownVisible]);

    const [isKirishActive, setKirishActive] = useState(false);

    const handleKirishClick = () => {
        setKirishActive(!isKirishActive);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false)


    const toggLangleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = (dropdown) => {
        setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setActiveDropdown(null);
    };

    return (
        <div className={`Navbar ${activeDropdown ? 'NavPen' : ''}`}>
            <div className="NavLeft First" >
                <img src={MyLogo} alt="" />
            </div>
            <div className="NavMidl">
                <button>Asosiy</button>
                <button>Yechimlar</button>
                <button>Afzalliklar</button>
                <button>Qo’shimcha imkoniyatlar</button>
                <button>Aloqa</button>
            </div>
            <div className="NavRight">
                <div className="LangBox" style={{ position: "relative" }} ref={dropdownRef}>
                    <p onClick={handleDropdownToggle} className={isDropdownVisible ? "activate" : ""} style={{ cursor: "pointer" }} >
                        {selectedLanguage}
                    </p>
                    <span></span>
                    <p onClick={handleKirishClick} className={isKirishActive ? "activeKirish" : ""} style={{ cursor: "pointer" }} >
                        Kirish
                    </p>

                    {isDropdownVisible && (
                        <div className="dropdown" style={{ position: "absolute", left: 0 }}>
                            <ul>
                                <li onClick={() => handleLanguageSelect("Uzb")}>Uzb</li>
                                <li onClick={() => handleLanguageSelect("Rus")}>Rus</li>
                                <li onClick={() => handleLanguageSelect("Eng")}>Eng</li>
                            </ul>
                        </div>
                    )}
                </div>
                <button>Bepul boshlash</button>
            </div>
            <div className="NavTop">
                <div className="NavLeft">
                    <img src={MyLogo} alt="" />
                </div>
                <div className="NavMidl">
                    <button>Asosiy</button>
                    <button>Yechimlar</button>
                    <button>Afzalliklar</button>
                    <button>Qo’shimcha imkoniyatlar</button>
                    <button>Aloqa</button>
                </div>
                <div className="NavRight_Mobile">
                    <div className="Lang_mob">
                        <button onClick={() => toggleDropdown('lang')}>
                            {selectedLanguage}
                        </button>
                    </div>
                    <div className="Menu_mob">
                        <button onClick={() => { toggleDropdown('menu'); toggLangleDropdown() }}>
                            <img src={isOpen ? X : Menu} alt="Menu" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={`DropdownMenu ${activeDropdown === 'lang' ? 'open' : ''}`}>
                <ul>
                    <li><button onClick={() => handleLanguageChange("Uzb")}>O’zbekcha</button></li>
                    <li><button onClick={() => handleLanguageChange("Qor")}>Qoraqalpoq</button></li>
                    <li><button onClick={() => handleLanguageChange("Рус")}>Русский язык</button></li>
                    <li className='Last_lang'><button onClick={() => handleLanguageChange("Eng")}>English languange</button></li>
                </ul>
            </div>
            <div className={`DropdownMenu ${activeDropdown === 'menu' ? 'open' : ''}`}>
                <ul>
                    <li><button>Asosiy</button></li>
                    <li><button>Yechimlar</button></li>
                    <li><button>Afzalliklar</button></li>
                    <li><button>Qo’shimcha imkoniyatlar</button></li>
                    <li><button>Aloqa</button></li>
                    <li><button>Kirish</button></li>
                    <div className="Last_Link_button">
                        <button>Bepul boshlash</button>
                    </div>
                </ul>
            </div>
        </div>
    );
}
