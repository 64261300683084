/* eslint-disable no-unused-vars */
import './Home.css'
import CRM_1 from './../../Assets/hero_bg.svg'
import React, { useState, useEffect, useRef } from 'react';
import contentData from './contentData.jsx';
import User from './../../Assets/Users.png';
import vector_1 from './../../Assets/Vector_1.png'
import vector_2 from './../../Assets/Vector_2.png'
import Ball from './../../Assets/Ball.png'
import CRM_2 from './../../Assets/CRM_2.png'
import { FaRegCircleCheck } from "react-icons/fa6";
import { FiMinusCircle } from "react-icons/fi";
import GroupsIcon from './../../Assets/groups.png';
import CoinsIcon from './../../Assets/coins.png';
import LessonType from './../../Assets/lesson type.png';
import Clock from './../../Assets/clock.png';
import Vector_3 from './../../Assets/Vector_3.png';
import Crm_3 from './../../Assets/CRM_4.png';
import box_1 from './../../Assets/Box_1.png';
import box_2 from './../../Assets/Box_2.png';
import BgBox from './../../Assets/BackgroundBox.png';
import GlavImgf from './../../Assets/fivesectionimg.png';
import Box_3 from './../../Assets/Box_3.png';
import Box_4 from './../../Assets/Box_4.png';
import Mac from './../../Assets/Mac.png';
import LineMac from './../../Assets/Za_mac.png';
import Down from './../../Assets/Down.png';
import Top from './../../Assets/Top.png';
import Click_mini from './../../Assets/Click_mini.png';
import Click_mini_click from './../../Assets/Click_mini_mini.png';
import Right_Click from './../../Assets/Right_Click.png';
import Left_Click from './../../Assets/Left_Click.png';
import instagram from './../../Assets/instagram 1.png';
import Telegram from './../../Assets/telegram 1.png';
import telefon from './../../Assets/phone-call 1.png';
import BackLast from './../../Assets/RTYUI.png';
import White_User from './../../Assets/Icon.svg';
import vector_1_white from './../../Assets/Vector_1_white.png';
import White_Ball from './../../Assets/white_ball.png';
import Vector_2_white from './../../Assets/Vector_2_white.png'

export default function Home() {

    const imgRef = useRef(null);

    const handleMouseMove = (e) => {
        const rect = imgRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const rotateX = ((y - centerY) / centerY) * -5;
        const rotateY = ((x - centerX) / centerX) * 5;
        imgRef.current.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = () => {
        imgRef.current.style.transform = `perspective(1000px) rotateX(0) rotateY(0)`; // Сброс трансформации при уходе мыши
    };

    const [contents, setContents] = useState([]);

    useEffect(() => {
        setContents(contentData);
    }, []);

    const [activeDropdown, setActiveDropdown] = useState(null);

    const dropdownData = [
        {
            title: "Nega aynan Myday platformasini tanlashim kerak ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Myday platformasi har qanday xolatda moslashuvchan bo’lgani sabab uni ishlatish sizning biznesingizga yaxshi samara bera oladi. Ko’p tizimlardagi muammolardan - real balansingiz
                        tizimga mos bo’lmasdan ortda qolib ketgan taqdirda ham Myday orqali qo’shimcha daromad yoki chiqim qo’shish evaziga real balans bilan platformadagi hisob-kitob balansni tenglay olasiz. Bu esa biznesning hisob-kitoblarini to’g’ri yurishi uchun xizmat ko’rsatadi.
                        <br />
                        <br />
                        Bundan tashqari esa o’quvchilar o’z kabinetida har bir dars uchun to’lagan to’lovlarini ko’rib bora olishadi va shunga o’xshagan yana bir qancha imkoniyatlar aynan bizni tanlashingiz uchun sabab bo’la oladi.
                    </p>
                </div>
            ),
        },
        {
            title: "Qanday ulanish mumkin ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Tizimning to’liq funksionalini ishga tushirgan xolda foydalanish uchun ushbu saytning eng pastgi qismidagi forma orqali biz bilan bog’lanishinigiz lozim bo’ladi. Yoki bizning quyidagi raqamlarimizga qo’ng’iroq qilib ulanishinigiz mumkin.
                        <br />
                        <br />
                        <br />
                        Telefon: +998 99 966 66 66
                        <br />
                        Telegram orqali: @username
                    </p>
                </div>
            ),
        },
        {
            title: "Myday tizimida maoshlarni qanday turda to’layman ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Myday tizimida maoshlarni siz istagan turda to’lash mumkin.
                        <br />
                        <br />
                        Agarda xodimlar oylik maosh evaziga ishlasa - siz bir xodim uchun belgilagan maosh tizimda har oy unga daromad sifatida yoziladi. Lekin faqatgina siz unga to’lovni amalga oshirilgandagina balansdan pul chiqim sifatida chiqarilib xodimga oylik maosh sifatida qo’shiladi.
                        <br />
                        <br />
                        Agarda o’qituvchilar foiz evaziga ishlasa - bunday xolatda ham har kuni ularning topgan daromadi sahifasiga yozilib boriladi va siz ularga to’lovni amalga oshirganingizda balansdan pul yechiladi.
                    </p>
                </div>
            ),
        },
        {
            title: "Click payme kabi dasturlar orqali to’lov qabul qilishim mumkinmi ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Ha albatta ! Myday orqali siz - Click, Payme, Paynet, Uzum bank, Alif hamda Zoomrad dasturlari orqali o’quvchilardan to’lovlarni qabul qila olasiz. Bularning barchasi esa avtomatik tarzda o’quvchining sahifasiga yoziladi hamda o’quv markaz balansiga ham qo’shilib boriladi. Barcha hisobotlarni esa moliya sahifasida ko’rib bora olasiz.
                        <br />
                        <br />
                        Dasturlarni ishga tushirish uchun o’quv markaz hamda dastur kompaniyasi bilan shartnoma qilish talab etiladi. Shundan so’ng texnik bo’lim xodimlarimiz tomonidan Myday platformasi aynan sizning hisob raqamingizga moslashtirib beriladi. Shartnoma qilish bo’yicha ham menejerlarimiz sizga to’liq bepul yordam berishadi.
                    </p>
                </div>
            ),
        },
        {
            title: "O’quvchilarni kirib-chiqishlarini avtomatik qilish mumkinmi ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Ha Myday bilan buning iloji bor ! Buning uchun esa har bir o’quvchiga o’z sahifasida berilgan QR kod’ni berishingiz kerak bo’ladi. O’quv markazga esa POS skaneri qo’yiladi va kompyuterga ulanadi. Shu orqali kelgan o’quvchi o’z QR kodini skanerga ko’rsatish yordamida ushbu kungi davomatga kelgani statusi avtomatik tizim tomonidan bajariladi.
                        <br />
                        <br />
                        Ushbu funksional bo’yicha barcha savollaringizni menejerlarimizga berishingiz mumkin. Avtomatik davomat tizimini ishga tushirishda bepul yordam beramiz.
                    </p>
                </div>
            ),
        },
        {
            title: "Tizim narxlari qanday ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Myday platformasini to’liq funksionali bilan foydalanishni xohlasangiz menejerlarimziga aloqaga chiqish orqali tizim narxlari va funksionallari bilan tanishishingiz mumkin. Kichik o’quv markazlar uchun ham samarali o’ziga xos tariflarimiz mavjud.
                        <br />
                        <br />
                        <br />
                        Telefon: +998 99 966 66 66
                        <br />
                        Telegram orqali: @username
                    </p>
                </div>
            ),
        },
        {
            title: "Tekin versiya ham mavjudmi ?",
            content: (
                <div className='ContantOne'>
                    <p>
                        Ha albatta ! Myday platformasini sinov tariqasida foydalanib ishga tushirib ko’rishingiz uchun 14 kunlik bepul versiyasi mavjud. Ushbu versiyada SMS funksionalidan tashqari barcha funksionallar to’liq ishga tushirilgan xolatda bo’ladi.
                        <br />
                        <br />
                        Bepul versiyani olish uchun sayt menyusidan “Bepul boshlash” tugmasini bosing !
                    </p>
                </div>
            ),
        },
        // Добавьте больше дропдаунов по необходимости
    ];

    const toggleDropdown = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    const getDropdownContentStyle = (index) => {
        return activeDropdown === index ? { maxHeight: '500px' } : { maxHeight: '0' };
    };

    return (
        <div className="Home">
            <div className="HomeBackground">
                <div className="HomeBackgroundBg"></div>
                <div className="HomeBackgroundmobile"></div>
                <div className="HomeBackgroundTitle">
                    <h2>O’quv markazidagi <br /> tartibsizliklar endi <span> <span id='prob'>&nbsp;</span> muammo emas !</span></h2>
                    <p>Myday bilan biznesni kundan kunga tartibli va samarali tizimlashtiring ! </p>
                    <button>Bepul boshlash</button>
                    <p>Sinov tarzida ishlatish uchun 14 kun bepul</p>
                </div>
                <div
                    className="HomeBackgroundCrmImg"
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    ref={imgRef}
                    style={{ transition: "transform 0.1s ease", transformStyle: "preserve-3d" }}
                >
                    <img src={CRM_1} alt="" />
                </div>
            </div>
            <div className="SecondBoxHome">
                <div className="SecondBoxHomeTitle">
                    <h2>Sizda ham quyidagicha muammolar mavjudmi ?</h2>
                    <p>Myday platformasi aynan shu muammolarni hal qilishda sizga ko’makchi bo’la oladi</p>
                </div>
                <div className="SecondBoxHomeContant">
                    <div className="SecondBoxHomeContantLeft">
                        <div className="SecondBoxHomeContantLeft_Up">
                            <div className="Users">
                                <img id='users' src={User} alt="" />
                                <img id='white_users' src={White_User} alt="" />
                            </div>
                            <div className="Title">
                                <h2>O’quvchilarning ota-onalari va to’lovlar</h2>
                                <p>bilan bog’liq hamda yosh o’quvchilardagi nazorat sustligi</p>
                            </div>
                            <div className="icon">
                                <img src={vector_1} alt="" />
                                <img src={vector_1_white} alt="" />
                            </div>
                        </div>
                        <div className="SecondBoxHomeContantLeft_Down">
                            {contents.map((content, index) => (
                                <div key={index} className="SecondBoxHomeContantLeft_DownBox">
                                    <div className="Users">
                                        <img id='users' src={content.imageUrl} alt={content.title} />
                                        <img id='white_users' src={content.imageUrlWhite} alt={content.title} />
                                    </div>
                                    <div className="Title">
                                        <h2>{content.title}</h2>
                                        <p>{content.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="SecondBoxHomeContantRight">
                        <div className="Users">
                            <img id='users' src={Ball} alt="" />
                            <img id='white_users' src={White_Ball} alt="" />
                        </div>
                        <div className="SecondBoxHomeContantRightTitle">
                            <h2>O’quvchilar</h2>
                            <p>davomatidagi turli <br />
                                xil tartibsizliklar va tushunmovchiliklar</p>
                        </div>
                        <div className="icon">
                            <img src={vector_2} alt="" />
                            <img src={Vector_2_white} alt="" />
                        </div>
                    </div>
                </div>
                <div className="SecondDawn">
                    <p>Biz ushbu muammolarning barcha yechimlarini bir platforma orqali beramiz. Myday o’quv markazidagi muammolar yechimini avtomatlashtirib hal qiling</p>
                </div>
            </div>
            <div className="TheardBoxHome">
                <div className="TheardBoxHomeTitle">
                    <h2>Moslashuvchan moliya tizimi</h2>
                    <p>Xodimlar bilan kelishuvga qaragan xolda ularning maoshlarini istalgan turda hisoblang !</p>
                </div>
                <div className="TheardBoxHomeBox">
                    <div className="TheardBoxHomeBoxLeft">
                        <div className="TheardBoxHomeBoxLeftImg"><img src={CRM_2} alt="" /></div>
                        <div className="Boxsms_One">
                            <div className="Boxsms_OneTop"><span><FaRegCircleCheck /></span><p>Yechib olindi</p></div>
                            <div className="Boxsms_OneMid">
                                <div className="Boxsms_OneMidDars"><span><FiMinusCircle /></span><p>Dars uchun</p></div>
                                <div className="Boxsms_OneMid_info_Box">
                                    <div className="Boxsms_OneMid_info"><span><img src={CoinsIcon} alt="" /></span><p>18 000 so’m</p></div>
                                    <div className="Boxsms_OneMid_info"><span><img src={GroupsIcon} alt="" /></span><p>14-guruh</p></div>
                                    <div className="Boxsms_OneMid_info"><span><img src={LessonType} alt="" /></span><p>Matematikadagi...</p></div>
                                </div>
                            </div>
                            <div className="Boxsms_OneBottom">
                                <div><p>#1383470</p></div>
                                <div><p>16:25 - 07.05.2024</p></div>
                                <div><span><img src={Clock} alt="" /></span></div>
                            </div>
                        </div>
                        <div className="Boxsms_Two">
                            <div className="Boxsms_OneTop"><span><FaRegCircleCheck /></span><p>Yechib olindi</p></div>
                            <div className="Boxsms_OneMid">
                                <div className="Boxsms_OneMidDars"><span><FiMinusCircle /></span><p>Dars uchun</p></div>
                                <div className="Boxsms_OneMid_info_Box">
                                    <div className="Boxsms_OneMid_info"><span><img src={CoinsIcon} alt="" /></span><p>18 000 so’m</p></div>
                                    <div className="Boxsms_OneMid_info"><span><img src={GroupsIcon} alt="" /></span><p>14-guruh</p></div>
                                    <div className="Boxsms_OneMid_info"><span><img src={LessonType} alt="" /></span><p>Matematikadagi...</p></div>
                                </div>
                            </div>
                            <div className="Boxsms_OneBottom">
                                <div><p>#1383470</p></div>
                                <div><p>16:25 - 07.05.2024</p></div>
                                <div><span><img src={Clock} alt="" /></span></div>
                            </div>
                        </div>
                        <div className="Boxsms_Three">
                            <div className="Boxsms_OneTop"><span><FaRegCircleCheck /></span><p>Yechib olindi</p></div>
                            <div className="Boxsms_OneMid">
                                <div className="Boxsms_OneMidDars"><span><FiMinusCircle /></span><p>Dars uchun</p></div>
                                <div className="Boxsms_OneMid_info_Box">
                                    <div className="Boxsms_OneMid_info"><span><img src={CoinsIcon} alt="" /></span><p>13 000 so’m</p></div>
                                    <div className="Boxsms_OneMid_info"><span><img src={GroupsIcon} alt="" /></span><p>43-guruh</p></div>
                                    <div className="Boxsms_OneMid_info"><span><img src={LessonType} alt="" /></span><p>26-dars. Rus tilida...</p></div>
                                </div>
                            </div>
                            <div className="Boxsms_OneBottom">
                                <div><p>#1303789</p></div>
                                <div><p>10:12 - 08.05.2024</p></div>
                                <div><span><img src={Clock} alt="" /></span></div>
                            </div>
                        </div>
                    </div>
                    <div className="TheardBoxHomeBoxRight">
                        <p>QO’SHIMCHA IMKONIYATLAR</p>
                        <h2>Moliyaviy moshlashuv</h2>
                        <p>Platformada barcha oylik abonement to’lovlari
                            o’quvchi hisobidan kunlik yechib olinish tartibida
                            o’quvchi shaxsiy profilida ko’rsatib boriladi.</p>
                        <button>Bepul boshlash</button>
                    </div>
                </div>
                <div className="LineGrande">
                    <img src={Vector_3} alt="" />
                </div>
            </div>
            <div className="FourBoxHomeBox">
                <div className="FourBoxHomeTitle">
                    <h2>Xodimlar uchun qulaylik</h2>
                    <p>Xodimlar bilan kelishuvga qaragan xolda ularning maoshlarini istalgan turda hisoblang !</p>
                </div>
                <div className="FourBoxHome">
                    <div className="FourBoxHomeLeft">
                        <p>QO’SHIMCHA IMKONIYATLAR</p>
                        <h2>Maosh to’lash tizimi</h2>
                        <p>Har bir xodim bilan ish haqini foiz orqali yoki belgilangan oylik ish haqi tizimida ishlash imkoniyati mavjud. Bundan tashqari xodimlar o’z profiliga kirish orqali kunlik va oylik hamda butun ish davri bo’yicha barcha ishlagan daromadlarini ko’rib borishlari mumkin.</p>
                        <button>Bepul boshlash</button>
                    </div>
                    <div className="FourBoxHomeRight">
                        <div className="FourBoxImg"><img src={Crm_3} alt="" /></div>
                        <div className="FourBoxSms"><p>Ularga berilgan bonus, ish haqi, jarima va turli xil chiqimlar ham o’quv markazi va o’qituvchi orasida to’liq shaffoflik uchun ko’rsatib boriladi. </p></div>
                        <div className="FourBoxOne"><img src={box_1} alt="" /></div>
                        <div className="FourBoxTwo"><img src={box_2} alt="" /></div>
                        <div className="FourBoxBgc"><img src={BgBox} alt="" /></div>
                    </div>
                </div>
            </div>
            <div className="FiveBoxHomeBox">
                <div className="FiveBoxHomeBoxTitle">
                    <h2>Online tarzda ota-ona nazorati</h2>
                    <p>Ota-ona nazorati funksiyasi orqali ularga farzandlarining o’quv markaz bilan bog’liq har bir amaliyotidan avomatik sms jo’natib boring</p>
                </div>
                <div className="FiveBoxHome">
                    <div className="FiveBoxHomeLeft">
                        <div className="FiveBoxHomeLeft_sms"><p>Har bir yangi amaliyot uchun ham sms jo’natish funksionalini ishga tushirishingiz mumkin. Yoki o’chirish ham o’z ixtiyoringizda bo’ladi.</p></div>
                        <div className="FiveBoxHomeLeft_boxOne"><img src={Box_3} alt="" /></div>
                        <div className="FiveBoxHomeLeft_boxTwo"><img src={Box_4} alt="" /></div>
                    </div>
                    <div className="FiveBoxHomeRight">
                        <p>QO’SHIMCHA IMKONIYATLAR</p>
                        <h2>Ota-ona nazorati</h2>
                        <p>Kichik yoshdagi bolalar uchun ota-ona nazorati tizimi farzandlarining barcha harakatlarini sms tarzida jonatib turadi. <br /><br />
                            Bu bilan esa ota-ona o’z farzandini olgan baholari va davomati bundan tashqari o’quv kurslari uchun qolgan qarzdorligi, qilingan to’lovlarini kuzatib borishi mumkin bo’ladi.
                        </p>
                        <button>Bepul boshlash</button>
                    </div>
                </div>
            </div>
            <div className="SixBoxHomeBox">
                <div className="SixBoxHomeBoxTitle">
                    <h2>Barchasi brendga mos bo’ladi</h2>
                    <p>Platforma to’liq sizning brendingiz logotipi bilan ishga tushirib beriladi</p>
                </div>
                <div className="SixBoxHome">
                    <div className="SixBoxHomeBoxLeft">
                        <p>QO’SHIMCHA IMKONIYATLAR</p>
                        <h2>Brending uchun</h2>
                        <p>Bizda barchasi siz xohlagandek bo’ladi.
                            Platforma to’liq sizning brendingiz logotiplari
                            bilan ishga tushiriladi.
                            <br />
                            <br />
                            Bu bilan esa platforma vizual ko’rinishida
                            aynan sizning brendingiz dasturi kabi
                            chiroyli ko’rinadi.
                        </p>
                        <button>Bepul boshlash</button>
                    </div>
                    <div className="SixBoxHomeBoxRight">
                        <div className="SixBoxHomeBoxRightMac"><img src={Mac} alt="" /></div>
                        {/* <div className="SixBoxHomeBoxRightText"><p>Sizning logo</p></div> */}
                    </div>
                    <div className="SixBoxHomeBg"><img src={LineMac} alt="" /></div>
                </div>
            </div>
            <div className="SevenBoxHomeBox">
                <div className="SevenBoxHomeBoxTitle">
                    <h2>Eng ko’p so’raladigan savollar</h2>
                    <p>Mijozlar tomonidan eng ko’p so’raladigan savollarga javoblar</p>
                </div>
                <div className="DropBoxContant">
                    <div className="DropBox">
                        {dropdownData.map((dropdown, index) => (
                            <div key={index}>
                                <div
                                    className={`DropTitle ${activeDropdown === index ? 'active' : ''}`}
                                    onClick={() => toggleDropdown(index)}
                                >
                                    <span className="Id">
                                        <p>{index + 1}</p> {dropdown.title}
                                    </span>
                                    <span>
                                        {activeDropdown === index ? (
                                            <span style={{ transform: 'rotate(180deg)' }}>
                                                <img src={Top} alt="" />
                                            </span>
                                        ) : (
                                            <span>
                                                <img src={Down} alt="" />
                                            </span>
                                        )}
                                    </span>
                                </div>
                                <div
                                    className="DropContent"
                                    style={{
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                        ...getDropdownContentStyle(index),
                                    }}
                                >
                                    <p>{dropdown.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div className="One_Box_ab"><img src={Click_mini} alt="" /></div>
                <div className="Two_Box_ab"><img src={Click_mini_click} alt="" /></div>
                <div className="Three_Box_ab"><img src={Right_Click} alt="" /></div>
                <div className="Four_Box_ab"><img src={Left_Click} alt="" /></div>

            </div>
            <div className="EghtBoxHomeBox">
                <div className="EghtBoxHomeBoxTitle">
                    <h2>Biz bilan aloqa</h2>
                    <p>To’liq funksional bilan tizimni ishga tushirish uchun bog’laning</p>
                </div>
                <div className="EightFormBox">
                    <label>
                        <p>Ismingiz*</p>
                        <input type="text" placeholder='Ismingizni kiriting' />
                    </label>
                    <label>
                        <p>Telefon raqam*</p>
                        <input type="tel" placeholder='Telefon raqamni kiriting' />
                    </label>
                    <label>
                        <p>Brend nomi</p>
                        <input type="text" placeholder='Brendingiz nomi qanday ?' />
                    </label>
                    <button type='submit'>Jo’natish</button>
                </div>
                <div className="EightlastBox">
                    <div className="EightlastBoxTitle">
                        <h2>Hoziroq bepul versiyani ishga tushiring</h2>
                        <p>Platforma sinov tarzida ishlatish uchun 14 kun bepul</p>
                    </div>
                    <button>Bepul boshlash</button>
                </div>
                <div className="Contacts">
                    <div className="Insta"><span><img src={instagram} alt="" /></span><h2>Instagram:</h2><p>@myday_uz</p></div>
                    <div className="Telegram"><span><img src={Telegram} alt="" /></span><h2>Telegram:</h2><p>@myday_uzb</p></div>
                    <div className="telefon"><span><img src={telefon} alt="" /></span><h2>Telefon: </h2><p>88 090-06-06</p></div>
                </div>
                <div className="Backgc"><img src={BackLast} alt="" /></div>

            </div>
            <div className="Footer">
                <p>© Copyright 2024</p>
            </div>
        </div>
    )
}