import image1 from './../../Assets/Soobsjenie.png';
import image2 from './../../Assets/file.png';
import image3 from './../../Assets/Coin.png';
import image4 from './../../Assets/bank.png';
import image1White from './../../Assets/sms_white.png';
import image2White from './../../Assets/White_file.png';
import image3White from './../../Assets/white_coins.png';
import image4White from './../../Assets/bank_White.png';

const contentData = [
  {
    title: "Potensial mijozlar bilan ",
    description: "qayta bo’glana olmaslik",
    imageUrl: image1,
    imageUrlWhite: image1White
  },
  {
    title: "Mijozlar bazasining",
    description: "to’liq shakllanmagani",
    imageUrl: image2,
    imageUrlWhite: image2White
  },
  {
    title: "O’qituvchilarning",
    description: "maoshlarida muammolar",
    imageUrl: image3,
    imageUrlWhite: image3White
  },
  {
    title: "Kirim-chiqim hamda",
    description: "daromad hisoboti yo’qligi",
    imageUrl: image4,
    imageUrlWhite: image4White
  }
];

export default contentData;